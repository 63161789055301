.page {
  &__image {

  }

  &__text {
    ol {
      counter-reset: heading;
      li {
        display: flex;
        &:before {
          margin-right: 13px;
          display: block;
          width: 7px;
          counter-increment: heading;
          content: "" counter(heading) ". ";
        }
      }
    }
  }
}

@include respond-up('medium') {
  .page {
    margin-bottom: 64px;

    &__container {
      max-width: 808px;
    }

    &__image {
      padding: 24px 0 32px;
    }

    &__text {
      padding-top: 16px;
    }
  }
}

@include respond-down('narrow') {
  .page {
    margin-bottom: 32px;

    &__image {
      padding: 16px 0 8px;
    }

    &__text {
      padding-top: 16px;
    }
  }
}