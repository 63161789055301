.content-header {
  padding: 48px 0 12px;
}

@include respond('medium') {
  .content-header {
    padding: 32px 0 12px;
  }
}

@include respond-down('narrow') {
  .content-header {
    padding: 24px 0 0px;
  }
}