.main-menu {
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {

  }

  &__link {
    display: block;
    padding: 10px 13px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000000;
    transition: $default-transition;

    &:hover {
      color: #959BA6;
    }

    &_active-menu {
      color: #959BA6;
    }
  }
}

@include respond-down('medium') {
  .main-menu {
    white-space: nowrap;

    &__item {
      .main-menu {
        &__link {
          &:first-child {
            padding-left: 16px;
          }

          &:first-child {
            padding-right: 16px;
          }
        }
      }
    }
  }
}