$max-row-width: 1192;
$max-container-width: 1192;

$small-spacer: 16px;
$medium-spacer: 32px;

$grid-columns: 12;
$grid: (
  small: (
    from: 0,
    to: 567,
    gutter: 12,
    spacer: 16
  ),
  narrow: (
    from: 568,
    to: 767,
    gutter: 12,
    spacer: 16
  ),
  medium: (
    from: 768,
    to: 1191,
    gutter: 12,
    spacer: 32
  ),
  large: (
    from: 1192,
    gutter: 30,
    spacer: 32
  )
);

$body-font-size: 16px;
$font: 'Rubik', sans-serif;
$h-font: 'Rubik', sans-serif;

$t: transparent;

$primary-color: #E71D0A;
$secondary-color: #153E8F;
$third-color: #E6E6E6;

$light-blue: #2272EB;

$dark-bg-color: #222222;
$dark-text-color: #222222;
$grey-text-color: #959BA6;

$grey-bg: #E9E9E9;
$grey-text-form: #8D8D8D;

$alert-color: $primary-color;

$default-transition: all 0.4s;

$default-button-height: 40px;
$default-button-height-small: 40px;

$default-input-height: 64px;
$default-input-height-small: 64px;