.ajax-form {
  padding-bottom: 16px;

  &__fields {
    padding: 0 16px;
  }

  &__field {
    margin-bottom: 16px;
  }

  &__actions {
    padding: 0 16px;
  }

  &__policy {
    font-size: 11px;
    line-height: 12px;
    margin: 16px auto 0;
    max-width: 270px;
    &_link {
      color: $grey-text-color;
    }
  }

  &__field {
    margin-bottom: 16px;
  }

  &__field-pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__field-pay-text {
    display: flex;
    flex-direction: column;
  }

  &__field-pay-title {
    font-weight: 500;
    font-size: 14px;
    color: $dark-text-color;
    line-height: 1.7;
  }

  &__field-pay-description {
    color: $grey-text-form;
    font-size: 12px;
  }

  &__field-pay-field {
    input {
      display: none;

      &+label{
        display: block;
        position: relative;
        width: 40px;
        min-width: 40px;
        height: 24px;
        border-radius: 100px;
        transition: $default-transition;
        background: $grey-bg;
        z-index: 1;
        pointer-events: auto !important;

        &:before{
          content: '';
          position: absolute;
          display: block;
          height: 16px;
          width: 16px;
          top: 4px;
          border-radius: 1000px;
          z-index: 2;
          left: 4px;
          transition: $default-transition;
          background-color: #fff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.22);
        }

      }

      &:checked{
        &+label{
          background: $secondary-color;

          &:before{
            background: white;
            left: 20px;
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .ajax-form {
    &__field-pay-text {
      margin-right: 16px;
    }
  }
}

@include respond-down('small') {
  .ajax-form {
    &__field-pay-text {
      margin-right: 16px;
    }
  }
}