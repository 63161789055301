.ajax-page {
  min-width: 320px;

  &__head {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 56px 0 16px;


    h1 {
      font-size: 16px;
    }
  }

  &__content {

  }
}