.cookie {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 6px 22px 0 #00000026;
  padding: 8px 8px 8px 16px;
  border-radius: 10px;
  background-color: white;
  z-index: 100;


  &__text {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    margin-right: 40px;

    a {
      color: #959BA6;
    }
  }

  &__button {
    z-index: 100;
    padding: 8px 16px;
    margin: 0;
    text-align: center;
    transition: $default-transition;
    display: inline-block;
    border: none;
    background: $primary-color;
    font-size: 14px;
    line-height: 171%;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
  }
}

@include respond-down('small') {
  .cookie {
    bottom: 45px;
  }
}