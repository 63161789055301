.sizes {
  --modal-padding: 16px;
  --gray-color: #F5F5F5;
  --border-radius: 4px;

  &__text {
    padding: 0 var(--modal-padding) var(--modal-padding);

    strong {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.33;
    }

    table {
      width: 100%;

      tr {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;

        &:first-child {
          height: 48px;

          td {
            text-align: center;
            vertical-align: middle;
            font-weight: 500;
          }
        }

        &:nth-child(2n+1) {
          background-color: var(--gray-color);
          border-radius: var(--border-radius);
          -webkit-border-radius: var(--border-radius);
          -moz-border-radius: var(--border-radius);
        }
      }

      td {
        flex: 1 1 auto;
        font-size: 14px;
        line-height: 1.14;

        p {
          font-size: 14px;
          white-space: nowrap;
          line-height: 1.14;
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 1.5;
    }

    a {
      color: $light-blue;
    }
  }
}

@include respond-up('large') {
  .sizes {
    &__text {

    }
  }
}

@include respond('medium') {
  .sizes {
    &__text {
      table {
        width: 100% !important;

        tbody {
          width: 100%;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .sizes {
    --modal-padding: 0;
    --modal-padding-small: 16px;

    &__text {
      overflow: hidden;
      padding-bottom: var(--modal-padding-small);

      table {
        display: inline-flex;
        height: 100% !important;
        overflow-x: scroll;
        margin-bottom: -15px;
        padding: 0 var(--modal-padding-small) 15px;

        td {
          min-width: 120px;
        }
      }

      table + p {
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 10;
        background-color: #fff;
      }

      p {
        padding: 0 var(--modal-padding-small) 0;
      }
    }
  }
}

@include respond-down('narrow') {
  .sizes {
    &__text {

    }
  }
}

@include respond-down('small') {
  .sizes {
    &__text {
      table {
        tr {
          height: 32px;
        }

        td {
          font-size: 12px;

          p {
            font-size: 12px;
          }
        }
      }

      strong {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}