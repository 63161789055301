.category {
  padding-bottom: 48px;

  &__menu {
    box-shadow: inset 0 -1px 0 0 $third-color;
  }

  &__menu-wrapper {
    margin: 0 -12px;
  }

  &__menu-items {
    display: flex;
    align-items: center;
  }

  &__menu-item {

  }

  &__menu-link {
    font-size: 12px;
    font-weight: 500;
    padding: 0 12px;
    display: flex;
    align-items: center;
    color: $grey-text-color;
    white-space: nowrap;

    &_active {
      color: $primary-color;
    }

    &_active, &:hover {
      .category__menu-link-text {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__menu-link-text {
    position: relative;
    display: flex;
    align-items: center;
    height: 46px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: $primary-color;
      opacity: 0;
      transition: all 0.4s;
    }
  }

  &__products {

  }

  &__products-list {

  }
}

@include respond-up('medium') {
  .category {
    &__menu {
      margin-bottom: 48px;
    }
  }
}

@include respond-down('narrow') {
  .category {
    &__menu {
      margin-bottom: 24px;
    }

    &__menu-wrapper {
      margin: 0;
    }
    &__menu-item {
      &:first-child {
        padding-left: 8px;
      }

      &:last-child {
        padding-right: 8px;
      }
    }

    &__menu-link {
      padding: 0 8px;
    }

    &__menu-link-text {
      height: 42px;
    }
  }
}