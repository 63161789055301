.products-list {
  display: flex;
  flex-wrap: wrap;

  &__item {

  }

  &__advantages {
    flex: 0 0 100%;
  }
}

@include respond-up('medium') {
  .products-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}

@include respond-up('large') {
  .products-list {
    margin: 0 -12px;

    &__item {
      padding: 0 12px;
      margin-bottom: 48px;
    }

    &__advantages {
      padding-top: 16px;
      margin-bottom: 64px;
    }
  }
}

@include respond('medium') {
  .products-list {
    margin: 0 -8px;

    &__item {
      padding: 0 8px;
      margin-bottom: 32px;
    }

    &__advantages {
      padding-top: 32px;
      margin: 0 -24px 64px;
    }
  }
}

@include respond-down('narrow') {
  .products-list {
    margin: 0 -4px;

    &__item {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 4px;
      margin-bottom: 32px;
    }

    &__advantages {
      padding-top: 16px;
      margin: 0 -12px 48px;
    }
  }
}

@include respond-down('small') {
  .products-list {
    &__item {
      margin-bottom: 24px;
    }

    &__advantages {
      padding-top: 16px;
      margin: 0 -12px 40px;
    }
  }
}