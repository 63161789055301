.present {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 8px;
    align-self: center;
  }

  &__description {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 48px;
    max-width: 337px;
    align-self: center;
  }

  &__image {
    width: 100%;
  }
}

@include respond-up('large') {
  .present {
    &__title {

    }

    &__description {

    }

    &__image {

    }
  }
}