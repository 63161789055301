.cart-item {
  display: grid;

  &__image {
    grid-area: image;
  }

  &__img {
    object-fit: cover;
    object-position: 50% 0;
    width: 72px;
    height: 108px;
  }

  &__name {
    grid-area: name;
    font-weight: 500;
  }

  &__preorder {
    grid-area: preorder;
  }

  &__preorder-value {
    margin-bottom: 18px;
    font-weight: 500;
    color: $primary-color;
    font-size: 12px;
  }

  &__chars {
    grid-area: chars;
  }

  &__char {
    font-size: 12px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__char-label {
    font-weight: 500;
  }

  &__char-value {

  }

  &__prices {
    grid-area: prices;
  }

  &__price {
    font-size: 16px;
    font-weight: 500;
    color: $dark-text-color;

    &_action {
      color: $primary-color;
      margin-bottom: 3px;
    }
  }

  &__old-price {
    font-size: 12px;
    font-weight: 500;
    color: $grey-text-color;
  }

  &__quantity {
    grid-area: quantity;
  }

  &__up-down {
    display: flex;
    align-items: center;
  }

  &__up-down-button {
    appearance: none;
    border: 1px solid #C4C4C4;
    border-radius: 16px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-text-color;
    font-size: 12px;
    cursor: pointer;
  }

  &__up-down-input {
    width: 40px;
    color: #000;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  &__remove {
    grid-area: remove;
  }
}


@include respond-up('large') {
  .cart-item {
    grid-template-columns: 120px 290fr 120fr 200fr 60fr;
  }
}
@include respond-up('medium') {
  .cart-item {
    grid-template-areas: "image name name name name"
                          "image preorder preorder preorder preorder"
                          "image chars quantity prices remove";

    &__quantity, &__remove {
      display: flex;
      align-items: center;
    }

    &__quantity {
      justify-content: center;
    }

    &__remove {
      justify-content: flex-end;
    }

    &__chars, &__prices {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__prices {
      padding-left: 48px;
    }
  }
}


@include respond('medium') {
  .cart-item {
    grid-template-columns: 96px 190fr 160fr 220fr 60fr;
  }
}

@include respond('narrow') {
  .cart-item {
    grid-template-areas: "image name name remove"
                        "image preorder preorder preorder"
                        "image chars quantity prices";

    grid-template-columns: 96px 150fr 180fr 110fr;

    &__quantity, &__remove {
      display: flex;
      align-items: center;
    }

    &__quantity {
      justify-content: center;
    }

    &__remove {
      justify-content: flex-end;
    }

    &__chars, &__prices {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__prices {
      align-items: flex-end;
    }

    &__name {

    }
  }
}

@include respond-down('narrow') {
  .cart-item {
    &__name {
      color: $grey-text-color;
      font-size: 12px;
    }
  }
}

@include respond-down('small') {
  .cart-item {
    grid-template-areas: "image name name remove"
                          "image preorder preorder preorder"
                          "image chars chars chars"
                          "image quantity prices prices";

    grid-template-columns: 106px 115fr 40fr 40fr;

    &__img {
      object-fit: cover;
      object-position: 50% 0;
      width: 96px;
      height: 136px;
    }

    &__prices {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    &__remove {
      display: flex;
      justify-content: flex-end;
    }

    &__name {
      margin-bottom: 10px;
    }

    &__chars {
      margin-bottom: 16px;
    }

    &__up-down-button {
      width: 32px;
      height: 32px;
      border-radius: 12px;
    }

    &__up-down-input {
      width: 32px;
      font-size: 12px;
    }

    &__price {
      font-size: 12px;
      margin-bottom: 3px;
    }

    &__old-price {
      font-size: 10px;
    }
  }
}