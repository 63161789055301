.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  position: relative;

  &__header {
    background-color: #fff;
    z-index: 100;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;

    &_border {
      &.header {
        border-bottom: none;
      }
    }
  }
}

@include respond-up('large') {
  .layout {
    &__header {

    }
  }
}

@include respond('medium') {
  .layout {
    &__header {

    }
  }
}

@include respond-down('narrow') {
  .layout {

  }
}