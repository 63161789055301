.advantage {
  max-width: 640px;

  &__image {
    margin-bottom: 8px;
  }

  &__text {
    font-size: 16px;
    padding: 16px;
  }
}

@include respond-down('small') {
  .advantage {
    &__image {
      
    }

    &__text {
      font-size: 12px;
    }
  }
}