.product {
  &__main {

  }

  &__container {

  }

  &__images {

  }

  &__thumbs {

  }

  &__thumb {

  }

  &__thumb-img {

  }

  &__images-gallery {
    position: relative;

    [data-controls] {
      appearance: none;
      position: absolute;
      top: 50%;
      width: 32px;
      height: 32px;
      border: none;
      z-index: 10;
      font-size: 0;
      background-color: transparent;
      transition: all 0.4s;
    }

    [data-controls="prev"] {
      background-image: url('../images/svg/prev.svg');
      left: 24px;
      transform: translateX(-16px);
      opacity: 0;
    }

    [data-controls="next"] {
      background-image: url('../images/svg/next.svg');
      right: 24px;
      transform: translateX(16px);
      opacity: 0;
    }

    &:hover {
      [data-controls="prev"] {
        transform: translateX(0);
        opacity: 1;
      }

      [data-controls="next"] {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .tns-nav {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 16px;
      background-color: #fff;
      border-radius: 8px;
      z-index: 10;
      display: flex;
      padding: 0 8px;

      button {
        appearance: none;
        border: none;
        padding: 0;
        height: 20px;
        width: 12px;
        background-color: transparent;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 10px;
          background-color: $third-color;
          top: 50%;
          left: 50%;
          margin-left: -2px;
          margin-top: -2px;
          transition: all 0.3s;
        }

        &.tns-nav-active {
          &:after {
            background-color: $primary-color;
          }
        }
      }
    }
  }

  &__images-slider {

  }

  &__image {

  }

  &__image-link {
    padding-top: (540px/360px)*100%;
    position: relative;
    display: block;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0;
  }

  &__info {

  }

  &__about {

  }

  &__name {
    margin-bottom: 32px;
  }

  &__chars {
    margin-bottom: 32px;
  }

  &__chars-list {

  }

  &__char {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__char-name {
    font-weight: 500;
  }

  &__char-value {

  }

  &__prices {
    display: flex;
    margin-bottom: 32px;
    align-items: center;

    &_opt {
      flex-direction: column;
      align-items: flex-start;

      .product {
        &__price {
          font-size: 20px;
        }
      }
    }
  }

  &__price {
    font-size: 32px;
    font-weight: 500;
    color: $dark-text-color;

    &_action {
      color: $primary-color;
    }
  }

  &__price-rr {
    color: #959BA6;
    font-size: 12px;
    line-height: calc(16/12);
  }

  &__old-price {
    font-size: 16px;
    font-weight: 500;
    color: $grey-text-color;
    padding-left: 24px;
  }

  &__buy {

  }

  &__sizes {
    border-top: 1px solid $third-color;
    padding-top: 32px;
  }

  &__sizes-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__sizes-call {
    font-size: 12px;
    font-weight: 500;
  }

  &__avail {
    display: flex;
    align-items: center;

    &._preorder {
      .product__avail-value {
        color: $primary-color;
      }

      .product__avail-sign {
        display: block;
      }
    }
  }

  &__avail-label {
    font-size: 12px;
    font-weight: 500;
    color: $grey-text-color;
  }

  &__avail-value {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }

  &__avail-sign {
    background: $dark-bg-color;
    border-radius: 10px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    padding: 1px 4px 0;
    margin-left: 4px;
    display: none;
  }

  &__avail-preorder-overlay {
    display: none;
  }

  &__avail-preorder {

  }

  &__avail-preorder-title {

  }

  &__avail-preorder-description {

  }

  &__sizes-container {

  }

  &__sizes-list {

  }

  &__sizes-list-item {

  }

  &__size {
    font-size: 12px;
    color: $dark-bg-color;
    border: 1px solid #C4C4C4;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    transition: all 0.4s;

    &._active {
      background-color: $dark-bg-color;
      color: #fff;
      border: 1px solid $dark-bg-color;
    }

    &:hover {
      border: 1px solid $dark-bg-color;
    }
  }

  &__size-avail-timer {
    position: absolute;
    right: -1px;
    top: -1px;
  }

  &__buttons {

  }

  &__button-wrapper {
    &:last-child {
      &._offer {
        a.button {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: inline-block;
          }
        }
      }

      a.button {
        &:first-child {
          display: inline-block;
        }

        &:last-child {
          display: none;
        }
      }
    }

    &:first-child {
      .button {
        &_large {
          display: inline-block;
        }

        &_small {
          display: none;
        }
      }
    }
  }

  &__tabs {

  }

  &__suggest {
    background-color: #F5F5F5;
    position: relative;
  }

  &__suggest-head {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__suggest-arrows {
    display: flex;
    align-items: center;
  }

  &__suggest-arrow {
    display: block;

    &:first-child {
      margin-right: 12px;
    }

    svg {
      path, rect {
        transition: all 0.4s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #fff;
        }

        rect {
          fill: $dark-bg-color;
          fill-opacity: 0.8;
        }
      }
    }
  }

  &__suggest-title {

  }

  &__suggest-list {

  }

  &__suggest-item {

  }

  &__tabs-list-wrapper {
    margin: 0 -8px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 8px;
      right: 8px;
      height: 1px;
      background-color: $third-color;
    }
  }

  &__tabs-list {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
  }

  &__tabs-list-item {
    &._active {
      .product {
        &__tabs-link {
          color: $primary-color;

          .product__tabs-link-text {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__tabs-link {
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px;
    display: flex;
    align-items: center;
    color: $grey-text-color;
    white-space: nowrap;

    &:hover {
      .category__menu-link-text {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__tabs-link-text {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: $primary-color;
      opacity: 0;
      transition: all 0.4s;
    }
  }

  &__tabs-content {
    padding-top: 18px;
    font-size: 16px;
    line-height: (24px/16px)*1em;
  }

  &__sizes-button {
    color: $light-blue;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    margin: -10px -10px 14px;
  }

  &__sizes-button-icon {
    margin-right: 8px;
  }

  &__sizes-button-text {

  }
}

@include respond-up('large') {
  .product {
    &__main {
      padding: 48px 0;
    }

    &__container {
      margin: 0 auto;
      max-width: 1128px;
      display: flex;
      justify-content: space-between;
    }

    &__images {
      flex: 0 0 550px;
      width: 550px;

      display: flex;
    }

    &__images-gallery {
      flex: 1 1 auto;
    }

    &__thumbs {
      width: 72px;
      flex: 0 0 72px;
      margin-right: 24px;
    }

    &__thumb-img {
      display: block;
    }

    &__thumb {
      margin-bottom: 8px;
      position: relative;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        box-shadow: inset 0 0 0 3px $primary-color;
        opacity: 0;
        transition: all 0.3s;
      }

      &._active {
        &:after {
          opacity: 1;
        }
      }
    }

    &__info {
      flex: 1 1 auto;
      margin-left: 120px;
      position: relative;
    }

    &__back {
      position: absolute;
      top: 0;
      left: -60px;
    }

    &__sizes-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px 8px;
    }

    &__sizes-list-item {
      padding: 0 6px 8px;
    }

    &__buy {
      margin-bottom: 40px;
    }

    &__avail._preorder {
      &:hover {
        & + .product__avail-preorder-overlay {
          display: block;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .product {
    &__char {
      font-size: 16px;
    }

    &__images-gallery {
      .tns-nav {
        visibility: hidden;
      }
    }

    &__suggest {
      padding: 48px 0;
    }

    &__suggest-head {

    }
  }
}

@include respond('medium') {
  .product {
    &__prices {
      margin: 24px 0;
      padding: 0 $medium-spacer;
    }

    &__sizes-top, &__buttons-wrapper {
      padding: 0 $medium-spacer;
    }

    &__about {
      margin: 32px 0 0;
      padding: 0 $medium-spacer;
    }

    &__tabs {
      padding: 0 $medium-spacer $medium-spacer;
    }

    &__suggest-head {
      padding: 0 $medium-spacer;
    }

    &__suggest-item {
      padding: 0 8px;
    }
  }
}


@include respond-down('medium') {
  .product {
    position: relative;

    &__back {
      position: absolute;
      top: 16px;
      left: 16px;

      svg {
        rect {
          stroke: none;
        }
      }
    }

    &__old-price {
      padding-left: 16px;
    }

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__buy {
      order: -1;
      padding-bottom: 32px;
      border-bottom: 1px solid $third-color;
    }

    &__sizes-list {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      margin-bottom: 16px;
    }

    &__sizes-list-item {
      padding: 0 4px;

      &:first-child {
        padding-left: $medium-spacer;
      }

      &:last-child {
        padding-right: $medium-spacer;
      }
    }

    &__thumbs {
      display: none;
    }

    &__images-gallery {
      [data-controls] {
        display: none;
      }
    }

    .tns-ovh {
    }

    &__suggest-list {
      display: flex;
      flex-wrap: nowrap;
    }

    &__suggest-item {
      .product-card {
        width: 224px;
      }

      &:first-child {
        padding-left: $medium-spacer;
      }

      &:last-child {
        padding-right: $medium-spacer;
      }
    }

    &__suggest-arrows {
      display: none;
    }

    &__sizes-button {
      padding: 0;
      margin: 0 0 24px 32px;
    }
  }
}

@include respond-up('narrow') {
  .product {
    &__buttons {
      display: flex;
      margin: 0 -6px;
    }

    &__button-wrapper {
      flex: 0 0 50%;
      padding: 0 6px;
    }

    &__sizes-top {
      position: relative;
    }

    &__avail._preorder {
      &._show {
        & + .product__avail-preorder-overlay {
          display: block;
        }
      }
    }

    &__avail-preorder-overlay {
      position: absolute;
      right: 0;
      top: 100%;
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      box-sizing: border-box;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 16px;
      z-index: 10;
      max-width: 290px;
    }

    &__avail-preorder {
      display: block;
      font-size: 12px;
    }

    &__avail-preorder-title {
      display: block;
      font-weight: 500;
    }

    &__avail-preorder-description {
      display: block;
    }
  }
}

@include respond-down('narrow') {
  .product {
    &__prices {
      margin: 16px 0;
      padding: 0 $small-spacer;
    }

    &__char {
      font-size: 12px;
    }

    &__price {
      font-size: 20px;
    }

    &__old-price {
      font-size: 12px;
    }

    &__sizes-top {
      padding: 0 $small-spacer;
    }

    &__buttons-wrapper {
      padding: 0 $small-spacer;
    }

    &__about {
      margin: 32px 0 0;
      padding: 0 $small-spacer;
    }

    &__tabs {
      padding: 0 $small-spacer 32px;
    }

    &__button-wrapper {
      margin-bottom: 8px;

      button.button {
        position: relative;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        pointer-events: auto;

        &._load {
          pointer-events: none;

          .product__button-add-text {
            opacity: 0;
          }

          .product__button-add-text-success {
            opacity: 1;
          }
        }
      }

      &:first-child {
        .button {
          &_large {
            display: none;
          }

          &_small {
            display: inline-flex;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__button-add-text, &__button-add-text-success {
      position: absolute;
      transition: $default-transition;
    }

    &__button-add-text {
      opacity: 1;
    }

    &__button-add-text-success {
      opacity: 0;
    }

    &__sizes-list-item {
      &:first-child {
        padding-left: $small-spacer;
      }

      &:last-child {
        padding-right: $small-spacer;
      }
    }

    &__suggest-head {
      padding: 0 $small-spacer;
    }

    &__suggest {
      padding: 32px 0;
    }

    &__suggest-item {
      padding: 0 4px;

      &:first-child {
        padding-left: $small-spacer;
      }

      &:last-child {
        padding-right: $small-spacer;
      }
    }

    &__sizes-button {
      padding: 0;
      margin: 0 0 24px 16px;
    }
  }
}

@include respond-down('small') {
  .product {
    &__avail._preorder {
      &._show {
        & + .product__avail-preorder-overlay {
          display: flex;
        }
      }
    }

    &__avail-preorder-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      align-items: flex-end;
      background: rgba(0, 0, 0, 0.8);
      z-index: 100;
    }

    &__avail-preorder {
      display: block;
      font-size: 12px;

      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      padding: 16px;
      z-index: 10;

      background: #fff url('../images/svg/close.svg') top 16px right 16px no-repeat;
    }

    &__avail-preorder-title {
      display: block;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 16px;
      padding-right: 30px;
    }

    &__avail-preorder-description {
      display: block;
    }

    &__tabs {
      padding: 0 $small-spacer 32px;
    }

    &__tabs-list-wrapper {
      overflow-y: hidden;
    }

    &__tabs-list {
      overflow-x: scroll;
      margin-bottom: -20px;
      padding-bottom: 20px;
    }
  }
}
