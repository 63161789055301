.product-card {
  display: block;

  &_suggest {
    .product-card {
      &__sizes-list-container {
        &::before {
          background: linear-gradient(90deg, #F5F5F5 50%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
          background: linear-gradient(270deg, #F5F5F5 50%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
  }

  &__images {
    padding-top: (540px/360px)*100%;
    position: relative;
    display: block;
    margin-bottom: 20px;

    .product-card__image[data-index="1"] {
      opacity: 1;
    }

    &:hover {
      .product-card__image[data-index="1"] {
        opacity: 0;
      }
    }
  }

  &__images-container {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background-size: cover;
    background-position: 50% 0;
  }

  &__image-hover-block {
    position: relative;
    z-index: 10;
    flex: 1 1 0;
    display: block;

    &:hover {
      &+.product-card__image {
        opacity: 1;
        &[data-index="1"] {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    opacity: 0;
    background-size: cover;
    background-position: 50% 0;
    transition: all 0.4s;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__prices {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }

  &__price {
    font-size: 16px;
    font-weight: 500;
    color: $dark-text-color;

    &_action {
      color: $primary-color;
    }

    &_opt {

    }

    &_rr {
      color: #959BA6;
      margin-left: 16px;
    }
  }

  &__old-price {
    font-size: 12px;
    font-weight: 500;
    color: $grey-text-color;
    padding-left: 10px;
  }

  &__name {
    display: block;
    color: $grey-text-color;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    padding-right: 4px;
  }

  &__mark {
    font-size: 12px;
    color: $dark-text-color;
  }

  &__sizes {
    margin-top: 4px;
    color: $grey-text-color;
    font-size: 12px;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 100%;
  }

  &__sizes-name {
    white-space: nowrap;
    position: relative;
    padding-right: 4px;
  }

  &__sizes-list-container {
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      background: linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      width: 50px;
      top: 0;
      bottom: 0;
      left: -40px;
      z-index: 10;
    }

    &::after {
      content: '';
      background: linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      width: 50px;
      top: 0;
      bottom: 0;
      right: -25px;
    }
  }

  &__sizes-list {
    overflow: scroll;
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: -10px;
    padding-bottom: 10px;
    position: relative;
  }

  &__sizes-item {
    padding: 0 4px;
    letter-spacing: 0;
  }
}

@include respond('medium') {
  .product-card {

  }
}


@include respond-down('small') {
  .product-card {
    &__prices {
      &_opt {
        flex-direction: column;
        align-items: flex-start;

        .product-card {
          &__price {
            font-size: 12px;
            margin-left: 0;
          }
        }
      }
    }

    &__old-price {
      order: 2;
      padding-left: 0;
    }

    &__price {
      order: 1;
      padding-right: 10px;

    }

    &__sizes-list-container {

    }

    &__sizes-list {
      margin-left: -4px;
    }

    &__sizes-name {
      display: none;
    }
  }
}

