$default-input-height: 40px !default;;

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    border: 1px solid #E6E6E6;
    box-shadow: none;
    margin: 0;
    padding: 0 16px;
    font-size: 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: $font;
    border-radius: 4px;
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 10px 16px;
    max-width: 100%;
    min-width: 100%;
  }

}