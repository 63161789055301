.main-grid {
  &__data {

  }

  &__promo-block {
    &:first-child {
      grid-area: promo1;
    }

    &:nth-child(2) {
      grid-area: promo2;
    }

    &:nth-child(3) {
      grid-area: promo3;
    }
  }

  &__promo {
    display: block;
    position: relative;

    &_square {
      .main-grid {
        &__promo-image {
          padding-top: 100%
        }
      }
    }
  }

  &__promo-image {
    display: block;
    padding-top: (686/456)*100%;
    position: relative;
  }

  &__promo-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: {
      position: 50% 0;
      repeat: no-repeat;
      size: cover;
    };
  }

  &__promo-name {
    font-weight: 500;
    padding: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    background-color: #fff;

  }

  &__wholesale {
    grid-area: wholesale;
  }

  &__advantages {
    grid-area: advantages;
  }
}

@include respond-up('large') {
  .main-grid {
    padding: 100px 0;

    &__data {
      display: grid;

      grid-template-areas: "promo1 promo3"
                           "promo1 wholesale"
                           "promo2 wholesale"
                           "promo2 advantages";
      grid-template-columns: 456fr 552fr;
      grid-column-gap: 120px;
      grid-row-gap: 100px;
    }

    &__promo-name {
      font-size: 20px;
      display: flex;
      align-items: center;
      padding: 32px;
      right: 100px;
    }

    &__promo {
      &_square {
        .main-grid {
          &__promo-name {
            right: 200px;
          }
        }
      }
    }

  }
}

@include respond('medium') {
  .main-grid {
    padding: 64px 0;

    &__data {
      display: grid;

      grid-template-areas: "promo1 promo3"
                            "promo1 advantages"
                            "promo2 advantages"
                            "wholesale wholesale";
      grid-template-columns: 284fr 344fr;
      grid-column-gap: 76px;
      grid-row-gap: 48px;
    }

    &__promo-name {
      right: 70px;
    }

    &__promo {
      &_square {
        .main-grid {
          &__promo-name {
            right: 130px;
          }
        }
      }
    }
  }
}

@include respond('narrow') {
  .main-grid {
    padding: 64px 0;

    &__data {
      display: grid;

      grid-template-areas: "promo1 promo2"
                            "promo3 promo3"
                            "wholesale wholesale"
                            "advantages advantages";
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 64px;
    }

    &__promo-name {
      right: 70px;
    }

    &__promo {
      &_square {
        max-width: 440px;
        margin: 0 auto;

        .main-grid {
          &__promo-name {
            right: 130px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .main-grid {
    padding: 64px 0;

    &__data {
      display: grid;
      grid-template-areas: "promo1"
                            "promo2"
                            "promo3"
                            "wholesale"
                            "advantages";
      grid-template-columns: 1fr;
      grid-row-gap: 60px;
    }

    &__promo-name {
      right: 70px;
      transform: translateY(50%);
    }

    &__promo {
      &_square {
        max-width: 440px;
        margin: 0 auto;

        .main-grid {
          &__promo-name {
            right: 130px;
          }
        }
      }
    }
  }
}