.wholesale {
  background: {
    image: url('../images/index/form-bg.png');
    position: 50% 50%;
    repeat: no-repeat;
    size: cover;
  };
  color: #fff;

  &__form {

  }

  &__top {

  }

  &__title {
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__fields {

  }

  &__field {
    margin-bottom: 16px;

    .floating-field {
      input[type="text"] {
        border-radius: 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        padding-left: 0;
        color: #fff;
        padding-top: 5px;
      }

      label {
        color: #fff;
        padding-left: 0;
      }

      input[type="text"], label {
        height: 44px;
      }

      &._filled label,
      input:focus + label,
      input._filled + label {
        top: -1px;
      }
    }
  }

  &__actions {
    padding-top: 16px;
  }

  &__policy {
    margin: 21px auto 0;
    font-size: 11px;
    line-height: 12px;
    &_link {
      color: #FFFFFF66;
    }
  }
}

@include respond-up('large') {
  .wholesale {
    padding: 40px;

    &__actions {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond('medium') {
  .wholesale {
    padding: 32px;

    max-width: 536px;
    margin: 24px auto 0;
  }
}

@include respond-up('narrow') {
  .wholesale {
    &__title {
      font-size: 24px;
    }
  }
}

@include respond('narrow') {
  .wholesale {
    padding: 32px;
  }
}


@include respond-down('small') {
  .wholesale {
    padding: 16px;

    &__title {
      font-size: 20px;
    }

    &__policy {
      margin: 16px auto 0;
      text-align: center;
      max-width: calc((230/375)*100vw);
    }

    &__actions {
      button {
        width: 100%;
      }
    }
  }
}