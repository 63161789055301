.main-advantages {
  &__list {
    display: grid;
  }

  &__list-item {

  }

  &__link {
    display: block;
  }

  &__icon {
    margin-bottom: 8px;
    display: block;

    svg {
      display: block;
    }
  }

  &__name {
    line-height: 150%;
    margin-bottom: 4px;
    font-size: 16px;
    display: block;
  }

  &__more {
    color: $grey-text-color;
    font-size: 12px;
    font-weight: 500;
    display: block;
  }
}

@include respond-up('large') {
  .main-advantages {
    &__list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 96px;
    }
  }
}


@include respond('medium') {
  .main-advantages {
    &__list {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 66px;
    }
  }
}


@include respond('narrow') {
  .main-advantages {
    &__list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 96px;
    }
  }
}

@include respond-down('small') {
  .main-advantages {
    &__list {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 32px;
    }

    &__name {
      font-size: 15px;
    }
  }
}