.success-product {
  --border: 1px solid #E6E6E6;

  min-width: 640px;
  padding: 0 16px 16px;

  &__info-container {
    display: flex;
    border-top: var(--border);
    border-bottom: var(--border);
    padding: 24px 0;
    align-items: center;
    margin-bottom: 16px;
  }

  &__image-container {
    margin-right: 24px;
  }

  &__image {
    width: 72px;
  }

  &__info {
    flex: 1 1 auto;
    width: 100%;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  &__offer-container {
    display: flex;
    justify-content: space-between;
  }

  &__info-list {
    display: flex;
    flex-direction: column;
    margin-bottom: -8px;
  }

  &__info-item {
    display: inline-flex;
    margin-bottom: 8px;
  }

  &__info-item-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.33;
    margin-right: 8px;
  }

  &__info-item-value {
    font-size: 12px;
  }

  &__price-container {
    align-self: flex-end;
    margin-right: 58px;
    font-weight: 500;
  }

  &__price {
    &._old {
      color: $primary-color;
    }
    color: $dark-text-color;
    font-size: 16px;
    line-height: 1.5;
  }

  &__price-old {
    font-size: 12px;
    color: #959BA6;
  }

  &__buttons {
    display: flex;
    margin: 0 -4px;
  }

  &__button {
    flex: 1 1 50%;
    margin: 0 4px;
  }
}

@include respond-up('large') {
  .success-product {
    &__info-container {

    }

    &__image-container {

    }

    &__image {

    }

    &__info {

    }

    &__name {

    }

    &__offer-container {

    }

    &__info-list {

    }

    &__info-item {

    }

    &__info-item-name {

    }

    &__info-item-value {

    }

    &__price-container {

    }

    &__price {

    }

    &__price-old {

    }

    &__buttons {

    }

    &__button {

    }
  }
}

@include respond ('medium') {

}

@include respond-down('narrow') {

}

@include respond-down('small') {

}