.floating-field {
  position: relative;

  label {
    position: absolute;
    height: 64px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: $dark-bg-color;
    font-size: 12px;
    transition: all 0.2s;
    margin: 0;
    padding-left: 16px;
  }

  &._filled label,
  &._filled-force label,
  input:focus + label,
  input._filled + label,
  textarea:focus + label{
    font-size: 11px;
    color: $grey-text-color;
    top: 10px;
    height: 14px;
  }

  &>input[type="text"], &>input[type="password"], &>input[type="search"] {
    padding-top: 18px;
  }

  &>textarea {
    padding-top: 25px;
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      //padding-left: 16px;
    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
      //padding-left: 14px;
      //font-size: 15px;
      //height: 64px;
    }
  }
}