.scroller {
  overflow: hidden;
  position: relative;

  &__container {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    margin-bottom: -40px;
  }

  &__data {

  }
}