.suggestions-wrapper {
  .suggestions-suggestions {
    border: 1px solid $third-color;
    border-radius: 4px;

    .suggestions-promo {
      display: none !important;
    }

    .suggestions-suggestion {
      padding: 6px 16px;
      font-size: 16px;
    }

    strong {
      font-weight: normal;
      color: $primary-color;
    }

    .suggestions-hint {
      padding: 4px 16px;
    }
  }
}