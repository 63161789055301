.cart-page {
  &._blocked {
    .cart-page {
      &__products {
        pointer-events: none;
      }
    }
  }
  &__top {

  }

  &__title {

  }

  &__container {
    max-width: 808px;
  }

  &__container-small {
    max-width: 616px;
  }

  &__message {
    padding: 16px;
    font-size: 12px;
    background-color: #FFF4E3;
    border-radius: 4px;

    &+.cart-page__products {
      .cart-page__list-item:first-child {
        border-top: none;
      }
    }
  }

  &__message-title {
    font-weight: 500;
    margin-bottom: 3px;
  }

  &__message-text {

  }

  &__products {

  }

  &__list {
    &._opt {
      .cart-page {
        &__list-item {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  &__list-item {
    border-top: 1px solid $third-color;
    padding: 24px 0;

    &:last-child {
      border-bottom: 1px solid $third-color;
    }
  }

  &__totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__totals-info {

  }

  &__totals-label {

  }

  &__totals-value {

  }

  &__totals-action {
    .button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }

  &__bottom {
    background-color: #F5F5F5;
    display: none;

    &._active {
      display: block;
    }
  }

  &__bottom-title {
    text-align: center;
  }

  &__form {

  }

  &__receiver {

  }

  &__form-block {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__form-num {
    width: 24px;
    height: 24px;
    color: #fff;
    background-color: $grey-text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    border-radius: 100px;
  }

  &__form-name {
    margin-left: 16px;
    font-size: 16px;
    color: $dark-text-color;
    font-weight: 500;
  }

  &__form-fields {

  }

  &__form-field {
    margin-bottom: 24px;

    .suggestions-addon {
      width: 14px !important;
      height: 14px !important;
      left: 550px !important;
      top: -40px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__delivery {

  }

  &__form-toggle {
    margin-bottom: 24px;

    .radio-list {
      display: flex;
      border-radius: 4px;
      border: 1px solid $secondary-color;
      overflow: hidden;
      position: relative;
      background-color: #fff;

      li {
        flex: 1 1 0;
      }

      input {
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        color: $secondary-color;
        background-color: #fff;
        font-size: 16px;
        margin: 0;
        transition: all 0.3s;
        cursor: pointer;
      }

      input:checked + label {
        background-color: $secondary-color;
        color: #fff;
      }
    }
  }

  &__address-hint {
    padding-top: 7px;
    color: $grey-text-color;
    font-size: 12px;
  }

  &__form-pickup {

  }

  &__map {

  }

  &__pickup-info {
    margin-top: 12px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__pickup-point {

  }

  &__pickup-address {
    display: block;
    margin-bottom: 8px;
  }

  &__pickup-worktime {

  }

  &__pickup-contacts {

  }

  &__pickup-phone {
    display: block;
    margin-bottom: 8px;
    text-align: right;
  }

  &__pickup-email {
    display: block;
    text-align: right;
  }

  &__radio {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    overflow: hidden;
    position: relative;
  }

  &__radio-head {
    padding: 20px 16px;
    border-bottom: 1px solid #E6E6E6;
    font-size: 16px;
    color: $dark-text-color;
    font-weight: 500;
  }

  &__radio-values {
    .radio-list {
      li {
        border-bottom: 1px solid #E6E6E6;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    input {
      display: none;
    }

    label {
      display: block;
      margin: 0;
      padding: 22px 16px 22px 52px;
      background: transparent url('../images/svg/radio-unchecked.svg') 16px 20px no-repeat;
      font-size: 16px;
      color: $dark-text-color;
    }

    input:checked + label {
      background-image: url("../images/svg/radio-checked.svg");
    }

    .radio-ship {
      label {
        padding-top: 20px;
        padding-bottom: 20px;
        display: grid;
        grid-template-areas: "name image"
                              "price image";
        cursor: pointer;
      }

      .ship-name {
        grid-area: name;
        margin-bottom: 2px;
      }

      .ship-price {
        color: $grey-text-color;
        grid-area: price;
      }

      .ship-image {
        grid-area: image;
        padding-left: 15px;
        display: flex;
        justify-content: flex-end;
        min-width: 80px;
      }

      .ship-img {
        object-fit: contain;
        width: 72px;
        height: 35px;
      }
    }
  }

  &__footer {
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    background-color: #fff;
  }

  &__footer-totals {
    padding: 16px 0;
    display: flex;
    border-bottom: 1px solid #E6E6E6;
  }

  &__footer-total {
    flex: 1 1 0;
    text-align: center;
  }

  &__footer-label {
    font-size: 12px;
    margin-bottom: 4px;
  }

  &__footer-value {
    font-weight: 500;
    font-size: 12px;
  }

  &__footer-actions {
    padding: 16px;
  }

  &__actions {

  }

  &__submit {
    height: 64px;
    line-height: 64px;
    padding: 0 20px;
    width: 100%;
  }

  &__agreement {
    color: $grey-text-color;
    font-size: 12px;
    margin-top: 10px;
  }
}

@include respond-up('large') {
  .cart-page {
    &__top {
      padding: 48px 0 48px;
    }

    &__title {
      margin-bottom: 48px;
    }

    &__bottom {
      padding: 64px 0;
    }
  }
}

@include respond-up('medium') {
  .cart-page {
    &__totals {
      padding-top: 24px;
    }

    &__totals-info {
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    &__totals-label {
      margin-right: 16px;
    }

    &__totals-value {
      font-weight: 500;
    }

    &__bottom-title {
      margin-bottom: 32px;
    }
  }
}

@include respond('medium') {
  .cart-page {
    &__top {
      padding: 32px 0 42px;
    }

    &__title {
      margin-bottom: 32px;
    }

    &__bottom {
      padding: 48px 0 32px;
    }
  }
}

@include respond-down('narrow') {
  .cart-page {
    &__top {
      padding: 24px 0 32px;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__totals {
      padding-top: 16px;
      flex-direction: column;
      align-items: stretch;
    }

    &__totals-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__totals-action {
      .button {
        width: 100%;
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }

    &__totals-label {
      margin-bottom: 2px;
      font-size: 16px;
    }

    &__totals-value {
      font-size: 16px;
      font-weight: 500;
    }

    &__bottom-title {
      margin-bottom: 24px;
    }
  }
}

@include respond('narrow') {
  .cart-page {
    &__bottom {
      padding: 32px 0 32px;
    }
  }
}

@include respond-down('small') {
  .cart-page {

    &__bottom-title {
      text-align: left;
    }

    &__top {
      padding: 24px 0 32px;
    }

    &__agreement {
      padding-bottom: 10px;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__list-item {
      padding: 16px 0;
    }

    &__bottom {
      padding: 32px 0 0;
    }

    &__pickup-info {
      display: block;
    }

    &__footer {
      margin: 0 -16px;
    }

    &__pickup-phone, &__pickup-email {
      text-align: left;
    }

    &__pickup-worktime {
      margin-bottom: 10px;
    }
  }
}

