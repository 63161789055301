.success-page {
  text-align: center;

  &__emoji {
    font-size: 32px;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 12px;
    margin-bottom: 24px;
  }

  &__button {

  }
}

@include respond-up('medium') {
  .success-page {
    padding: 150px 0 200px;
  }
}

@include respond('narrow') {
  .success-page {
    padding: 90px 0 140px;
  }
}

@include respond-down('small') {
  .success-page {
    padding: 48px 0 64px;
  }
}