.header {
  border-bottom: 1px solid #E6E6E6;

  &_border {
    border-bottom: none;
  }

  &__top {

  }

  &__top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__logo {
    padding: 16px 12px 16px 0;

    img {
      display: block;
    }
  }

  &__menu {

  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__phone {
    font-weight: 500;
    letter-spacing: (1.33px/16px)*1em;
    margin-right: 10px;
  }

  &__icons {
    display: flex;
    align-items: center;
  }

  &__lang {
    display: inline-flex;
    align-items: center;
    padding: 15px;
    margin-right: -15px;
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 10px;
  }

  &__lang-icon {
    svg {
      display: block;
    }
  }

  &__lang-text {
    line-height: 1;
    font-size: 10px;
    padding-left: 4px;
  }

  &__cart {
    display: inline-flex;
    align-items: center;
    padding: 15px;
    margin-right: -15px;
    position: relative;

    /*&::after {
      content: '';
      display: block;
      position: absolute;
      height: 6px;
      min-width: 6px;
      width: 6px;
      right: 13px;
      top: 16px;
      background-color: $primary-color;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      opacity: 0;
      transition: $default-transition;
    }

    &._active {
      &::after {
        opacity: 1;
      }
    }*/
  }

  &__cart-icon {

  }

  &__cart-count {
    margin-left: 4px;
    height: 16px;
    border-radius: 16px;
    background-color: $primary-color;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0;
    text-transform: uppercase;
    min-width: 16px;
    display: inline-flex;
    justify-content: center;
    padding: 0 4px;
    align-items: center;
    line-height: 1;
  }

  &__bottom {

  }

  &__bottom-content {

  }
}

@include respond-up('large') {
  .header {
    &__phone {
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__top {
      border-bottom: 1px solid #E6E6E6;
    }

    &__bottom-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__phone {
      font-size: 12px;
    }

    &__logo {
      padding: 8px 0;
    }
  }
}