$grid: () !default;
$max-container-width: 1200 !default;

.container {
  max-width: #{$max-container-width}px;
  margin: 0 auto;
}

@each $name, $data in $grid {
  @if map-has_key($data, spacer) {
    @include respond($name) {
      .container {
        &_spacer {
          padding: 0 #{map-get($data, spacer)}px;
        }
      }
    }

    @include respond-up($name) {
      .container {
        &_spacer-#{$name}-up {
          padding: 0 #{map-get($data, spacer)}px;
        }
      }
    }

    @include respond-down($name) {
      .container {
        &_spacer-#{$name}-down {
          padding: 0 #{map-get($data, spacer)}px;
        }
      }
    }
  }
}