.footer {
  background-color: $dark-bg-color;
  color: $grey-text-color;
  font-size: 12px;

  &__top {
    border-bottom: 1px solid rgba(196, 196, 196, 0.13);
  }

  &__top-content {
    padding: 24px 0;
  }

  &__phone {
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &__center {

  }

  &__policy-hover {
    transition: $default-transition;
    &:hover {
      color: white;
    }
  }

  &__center-content {
    padding: 32px 0;
  }

  &__menu-container {
    display: flex;
    flex-direction: column;
  }

  &__menu {

  }

  &__menu-column {

  }

  &__menu-column-name {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 16px;
  }

  &__menu-list {

  }

  &__menu-item {
    margin-bottom: 8px;
    font-size: 12px;

    &:last-child {
      margin-bottom: 0;
    }


  }

  &__menu-link {
    color: $grey-text-color;
    transition: $default-transition;

    &:hover {
      color: white;
    }
  }

  &__cart {
    margin-bottom: 24px;
  }

  &__cart-text {
    font-weight: 500;
    color: #FFF;
    font-size: 12px;
  }

  &__cart-counter {
    margin-left: 4px;
    height: 16px;
    border-radius: 16px;
    background-color: $primary-color;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0;
    text-transform: uppercase;
    min-width: 16px;
    display: inline-flex;
    justify-content: center;
    padding: 0 4px;
    align-items: center;
    line-height: 1;
  }

  &__policy {

  }

  &__bottom {

  }

  &__bottom-content {

  }

  &__rights {

  }

  &__author {

  }

  &__author-link {

  }
}

@include respond-up('large') {
  .footer {
    &__phone {
      font-size: 24px;
    }

    &__center-content {
      display: flex;
      justify-content: space-between;
    }

    &__menu-container {
      flex: 0 0 65%;
      width: 65%;
    }

    &__menu {

    }

    &__policy {
      flex: 0 0 35%;
      width: 35%;
      padding-left: 40px;
      text-align: right;

      display: flex;
      justify-content: flex-end;
    }

    &__policy-info {
      max-width: 240px;
    }

    &__bottom-content {
      padding-bottom: 32px;
    }

    &__policy {
      margin-top: -32px;
    }
  }
}


@include respond-up('narrow') {
  .footer {
    &__top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__menu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &__menu-column {
      flex: 0 0 22%;
    }

    &__bottom-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__policy {
      padding-top: 32px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__center {
      border-bottom: 1px solid rgba(196, 196, 196, 0.13);
    }

    &__bottom-content {
      padding: 32px 0;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__menu {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -12px;
    }

    &__menu-column {
      flex: 0 0 50%;
      margin-bottom: 32px;
    }

    &__top-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__phone {
      margin-bottom: 10px;
    }

    &__bottom-content {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__rights {
      margin-bottom: 10px;
    }
  }
}