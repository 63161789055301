.ajax-success {
  padding: 0 16px 16px;
  text-align: center;

  &__emoji {
    font-size: 32px;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 12px;
  }

  &__button {
    margin-top: 16px;
  }
}