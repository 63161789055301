.opt {
  font-size: 14px;
  background-color: #ECE6E2;
  color: #222222;
  text-align: center;
  padding: 12px;

  &_border {
    border-radius: 8px;
    margin-bottom: 24px;
  }

  &_cart {
    line-height: calc(24/16);
    border-radius: 8px;
  }

  &__link {
    color: #8F7766;
  }
}

@include respond-down('small') {
  .opt {
    font-size: 14px;
  }
}