.main-slider {
  &__container {
    position: relative;
  }

  &__list {

  }

  &__slide {

  }

  &__link {
    display: block;
    position: relative;
  }

  &__image-wrapper {
    position: relative;
    display: block;
    z-index: 5;
  }

  &__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    background: {
      position: 0 50%;
      repeat: no-repeat;
      size: cover;
    };
    z-index: 5;
  }

  &__info {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    padding: 16px;
    padding-right: 110px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  .tns-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
  }

  [data-controls] {
    appearance: none;
    top: 50%;
    width: 44px;
    height: 60px;
    border: none;
    z-index: 10;
    font-size: 0;
    background-color: transparent;
    transition: all 0.4s;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  [data-controls="prev"] {
    background-image: url('../images/svg/slide-white-prev.svg');
  }

  [data-controls="next"] {
    background-image: url('../images/svg/slide-white-next.svg');
  }
}

@include respond-up('large') {
  .main-slider {
    &__info {
      padding: 40px 56px;
    }

    &__title {
      font-size: 24px;
    }

    .tns-controls {
      padding: 27px 56px;
    }

    .tns-controls, &__info {
      right: 43%;
    }
  }
}

@include respond('medium') {
  .main-slider {
    .tns-controls, &__info {
      right: 46%;
    }
  }
}

@include respond('narrow') {
  .main-slider {
    .tns-controls, &__info {
      right: 30%;
    }
  }
}


@include respond-up('narrow') {
  .main-slider {
    &__image-wrapper {
      padding-top: (624/1192)*100%;
    }
  }
}


@include respond-down('small') {
  .main-slider {
    .tns-controls, &__info {
      right: 10%;
    }

    &__image-wrapper {
      padding-top: (480/320)*100%;
    }
  }
}