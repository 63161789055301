.button {
  padding: 8px 16px;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  background: $primary-color;
  font-size: 14px;
  line-height: 171%;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;

  &_expand, &_wide {
    width: 100%;
    display: block;
  }

  &_secondary {
    background-color: $secondary-color;
  }

  &_third {
    background-color: $third-color;
    color: $dark-text-color;
  }

  &_white {
    background-color: #fff;
    color: $dark-text-color;
  }
}

@include respond-up('large') {
  .button {
    &:hover {
      background-color: transparent;
      box-shadow: inset 0 0 0 1px $primary-color;
      color: $primary-color;
    }

    &_secondary {
      &:hover {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px #C4C4C4;
        color: $dark-text-color;
      }
    }

    &_third {
      &:hover {
        background-color: transparent;
        box-shadow: inset 0 0 0 1px #C4C4C4;
        color: $dark-text-color;
      }
    }

    &_white {
      &:hover {
        background-color: $third-color;
        color: $dark-text-color;
        box-shadow: none;
      }
    }
  }
}