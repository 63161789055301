.category-advantages {
  white-space: nowrap;

  &__list {
    display: flex;
  }

  &__list-item {
    flex: 1 1 0;
  }

  &__link {
    display: block;
  }

  &__icon {
    display: block;
    margin-bottom: 8px;

    svg {
      display: block;
    }
  }

  &__name {
    display: block;
    color: $dark-text-color;
    font-size: 16px;
    line-height: (24px/16px)*100%;
    margin-bottom: 4px;
  }

  &__more {
    display: block;
    color: $grey-text-color;
    font-size: 12px;
    font-weight: 500;
  }
}

@include respond-up('large') {
  .category-advantages {
    border: 1px solid $third-color;
    border-radius: 16px;
    padding: 24px;
  }
}

@include respond-down('medium') {
  .category-advantages {
    &__link {
      border: 1px solid $third-color;
      border-radius: 16px;
      padding: 24px 16px;
      width: 200px;
    }

    &__list-item {
      padding: 0 4px;
    }
  }
}

@include respond('medium') {
  .category-advantages {
    &__list-item {
      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }
    }
  }
}

@include respond('narrow') {
  .category-advantages {
    &__list-item {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}

@include respond-down('small') {
  .category-advantages {
    &__list-item {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }
}